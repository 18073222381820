<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item open ref="debit">
        <div slot="header">
          <h3 class="text-left font-normal">Debit account <span class="text-2xl mid-blue">*</span></h3>
        </div>
        <p>We'll debit repayments from the account noted below</p>
        <vs-row class="mt-8">
          <vs-col vs-xs="12" vs-lg="6" v-if="enableAccountWidget">
            <div>
              <vs-select class="mt-4 w-3/5" v-model="selectedAccount" @change="handleAccountChange">
                <vs-select-item :key="index" :value="item.bankId" :text="`${item.displayName} (${item.bsb} , ${item.accountNumber})`" v-for="(item, index) in banks" />
              </vs-select>
            </div>
          </vs-col>
          <vs-col vs-xs="12" vs-lg="6">
            <div class="flex flex-col gap-y-8 mb-8 mt-4 ds-wrapper">
              <div class="w-full">
                <vs-input name="accountName" label="Account name" v-model="manualDebit.displayName" disabled />
              </div>
              <div class="flex flex-row gap-x-6">
                <div class="w-2/5">
                  <vs-input label="BSB" name="disbursementBsb" placeholder="000-000" v-mask="'###-###'" v-model="manualDebit.bsb" disabled />
                </div>
                <div class="w-3/5">
                  <vs-input name="accountNumber" label="Account number" v-model="manualDebit.accountNumber" disabled />
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <span class="text-danger" v-if="errors.has('debitAccount')">{{ errors.first("debitAccount") }}</span>
        </vs-row>

        <div class="flex flex-row custom-quillEditor legals-custom payment-legals" v-if="autoDebit">
          <div>
            <vs-checkbox class="mt-1" name="directDebitTerm" id="directDebitTerm" data-vv-as="Direct debit terms and conditions" v-validate="'required'" v-model="directDebitTerm"></vs-checkbox>
          </div>
          <div>
            <p class="request-form-debit" v-html="latestDirectDebitTerms.explicitTerms"></p>
            <span class="text-danger text-sm" v-show="errors.has('directDebitTerm')">{{ errors.first("directDebitTerm") }}</span>
          </div>
        </div>

        <div class="w-full mt-8 flex">
          <vs-switch v-model="overrideReference" />
          <p class="ml-2">Override the default reference we'll use when debiting your account</p>
        </div>

        <div class="mt-5 ds-wrapper w-1/2">

          <label class="vs-input--label">
            Debit transaction reference
            <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '50px, 2px' }}}">
              <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
              <div class="popper py-2 px-4 text-center custom-popper">
                <p class="text-primary">Letters, numbers and spaces only, <= 18 characters, it may still be edited/truncated by your bank.</p>
              </div>
            </popper>
          </label>
          <template v-if="overrideReference">
            <vs-input
              data-vv-as="reference"
              name="debitReference"
              v-model="debitReference"
              maxlength="280"
              v-validate="'required|alphaNumSpace'"
              data-vv-validate-on="blur"
              @keypress="handleReferenceKeyPress($event)"
              @blur="handleChangeReference()"
            />
            <span class="text-danger" v-if="errors.has('debitReference')">{{ errors.first("debitReference") }}</span>
          </template>
          <template v-else>
            <vs-input name="debitReference" v-model="merchantDebitReference" :disabled="true" />
          </template>
        </div>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
import { mask } from "vue-the-mask";
import Popper from "vue-popperjs";
import ToolTipIcon from "../../../../components/design-sys-components/icons/ToolTipIcon";

export default {
  components: { Popper, ToolTipIcon },
  props: ["autoDebit", "fullTerms", "banks", "debit", "isSubmitted"],
  data() {
    return {
      appUrl: process.env.VUE_APP_ROOT_API,
      manualDebit: {
        displayName: null,
        bsb: null,
        accountNumber: null
      },
      selectedAccount: null,
      latestDirectDebitTerms: null,
      directDebitTerm: "",
      merchantDebitReference: "Our request ID",
      overrideReference: false,
      debitReference: ""
    };
  },
  directives: { mask },
  watch: {
    async isSubmitted(val) {
      if (val && (!this.debit || !this.debit.bankId)) {
        this.errors.add({
          field: "debitAccount",
          msg: "Please select debit account"
        });
      }
    },

    directDebitTerm(val) {
      if (val) {
        const data = { val: val, directDebit: true, termsId: this.latestDirectDebitTerms._id };
        this.$emit("on", data);

        return;
      }

      this.directDebitTerm = null;
    },

    overrideReference(val) {
      if (!val) {
        this.debitReference = null;
      }
    }
  },
  methods: {
    handleAccountChange() {
      this.manualDebit = this.banks.find((account) => account.bankId == this.selectedAccount);
      this.$emit("changeDebitAccount", this.selectedAccount);

      if (this.errors.has("debitAccount")) {
        this.errors.remove("debitAccount");
      }
    },

    handleReferenceKeyPress(event) {
      const charCode = event.which || event.keyCode;

      // Check if the pressed key corresponds to a special character
      if (charCode >= 33 && charCode <= 47 ||
          charCode >= 58 && charCode <= 64 ||
          charCode >= 91 && charCode <= 96 ||
          charCode >= 123 && charCode <= 126) {
          event.preventDefault();
      }
    },

    handleChangeReference() {
      this.$emit("handleChangeDebitReference", this.debitReference);
    },

    handleTerms(terms) {
      const privacyTerms = terms.privacy;
      const websiteTerms = terms.websiteTerms;

      this.latestDirectDebitTerms = terms.directDebitTerms;
      this.latestDirectDebitTerms.explicitTerms = this.replaceLegalContent(
      this.latestDirectDebitTerms.explicitTerms,
        {
          fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.latestDirectDebitTerms._id}?type=DIRECT_DEBIT&fullDocument=true" target="_blank">${this.latestDirectDebitTerms.documentName}</a>`,
          privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${privacyTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${privacyTerms.documentName}</a>`,
          generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${websiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${websiteTerms.documentName}</a>`,
        }
      );
    },
  },
  mounted() {
    if (this.debit && this.debit.bankId) {
      const selectedBank = this.banks.findIndex((account) => account.bankId == this.debit.bankId);

      if (selectedBank >= 0) {
        this.selectedAccount = this.banks[selectedBank].bankId;
        this.manualDebit = this.banks[selectedBank];
      } else if (this.banks && (this.banks.length == 1)) {
        this.selectedAccount = this.banks[0].bankId;
        this.manualDebit = this.banks[0];
      }
    }

    this.handleTerms(this.fullTerms);

    if (this.selectedAccount) {
      this.$emit("changeDebitAccount", this.selectedAccount);
    }
  },
  computed: {
    enableAccountWidget() {
      let enabled = this.debit && this.debit.allowOverride;

      if (enabled && this.banks && (this.banks.length == 1)) {
        enabled = false;
      }

      return enabled;
    }
  },
};
</script>

