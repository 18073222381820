<template>
  <vs-row>
    <vs-col>
      <label for="emailAddress" class="block w-full text-xm font-normal mt-8 pb-3 text-base">Email address <span class="mid-blue">*</span></label>
      <div v-for="(newEmail, index) in multipleEmailAddress" :key="index" class="flex flex-row justify-between mb-2">
        <div class="mr-8 w-full">
          <vs-input size="large" v-model="newEmail.email" data-vv-validate-on="blur" data-vv-as="email address" :name="'newEmail'+index"
            :id="'multipleEmailAddress'+index" class="w-full" v-validate="'required|email'" :disabled="checkDisabled(index)" @change="changesEmail" />
          <span class="text-danger text-sm" v-show="errors.has('newEmail'+index)">{{ errors.first("newEmail"+index) }}</span>
        </div>
        <feather-icon v-if="deleteEmail(index)" icon="Trash2Icon" @click="removeEmail(index)" />
      </div>
      <span @click="addEmail" v-if="allowAddEmail" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
    </vs-col>
  </vs-row>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import _ from "lodash";

export default {
  components: { PlusIcon },
  props: ["isSubmitted", "remittanceAdvice"],
  data() {
    return {
      notificationDisabled: false,
      multipleEmailAddress: [],
      templateEmailAddress: [],
    };
  },
  mounted() {
    this.assignAccountEmail();
  },
  watch: {
    async isSubmitted() {
      this.startValidating();
    },
    remittanceAdvice: {
      handler(val) {
        if (val) {
          this.assignAccountEmail();
        }
      },
      deep: true
    }
  },
  methods: {
    removeEmail(index) {
      this.multipleEmailAddress.splice(index, 1);
      this.remittanceAdvice.emailAddress = this.multipleEmailAddress;
      const key = this.errors.items.findIndex(object => { return object.field === "newEmail" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },
    changesEmail() {
      this.remittanceAdvice.emailAddress = this.multipleEmailAddress;
    },
    addEmail() {
      this.multipleEmailAddress.push({ email: "" });
    },
    async startValidating() {
      await this.$validator.validate();
    },
    checkDisabled(index) {
      return (!this.remittanceAdvice.allowOverride && this.templateEmailAddress.includes(this.multipleEmailAddress[index]));
    },
    deleteEmail(index) {
      return this.multipleEmailAddress.length > 1 && !this.checkDisabled(index);
    },
    assignAccountEmail() {
      if (this.remittanceAdvice && this.remittanceAdvice.emailAddress && this.remittanceAdvice.emailAddress.length > 0) {
        this.multipleEmailAddress = [...this.remittanceAdvice.emailAddress];
        this.templateEmailAddress = [...this.multipleEmailAddress];
      }
    }
  },
  computed: {
    allowAddEmail() {
      return (this.remittanceAdvice.allowAdd || this.remittanceAdvice.allowOverride);
    }
  }
};
</script>

