<template>
  <vs-card class="main-settings req-temp-container mt-5">
    <div slot="header" class="ds-wrapper flex">
      <h2 class="text-left font-normal explainer-heading">{{ explainer.header }}</h2>
      <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-35px, 2px' }}}" class="ml-auto">
        <template slot="reference" class="top">
          <ToolTipIcon width="14px" height="14px" class="vs-icon" v-if="productConfigType != 'TWO_PARTY'" />
        </template>
        <div class="popper py-2 px-4 text-center custom-popper">
          <p class="text-primary">FAQ/Explainer for borrower, displayed on payment page</p>
        </div>
      </popper>
    </div>
    <p class="custom-texteditor" v-html="explainer.content"></p>
  </vs-card>
</template>
<script>
  import Popper from "vue-popperjs";
  import ToolTipIcon from "../../../../components/design-sys-components/icons/ToolTipIcon";

  export default {
    components: { Popper, ToolTipIcon },

    props: {
      explainer : {
        type: Object
      },
      productConfigType: {
        type: String
      }
    },
  }
</script>

