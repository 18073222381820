<template>
  <vs-card class="accordin-card">
    <vs-collapse accordion>
      <vs-collapse-item :open="editableDisbursement || selectedPlan.allowDisbursementDateOverride" ref="disbursement">
        <div slot="header">
          <h3 class="text-left font-normal">Disbursement <span class="text-2xl mid-blue" v-if="editableDisbursement">*</span></h3>
        </div>
        <p v-if="disbursement.type == 'customer'">Customer will provide disbursement account details, via payment page.</p>
        <p v-else-if="selectedPlan.editDisbursement && selectedPlan.editDisbursement.customer">We'll transfer funds to the account selected below, or the account provided by your customer</p>
        <p v-else>We'll transfer funds to the account selected below</p>
        <vs-row class="mt-8" v-if="disbursement.type != 'customer'">
          <vs-col vs-xs="12" vs-lg="6" v-if="enableAccountWidget">
            <vs-radio v-if="displayOptions" v-model="disbursement.method" vs-value="account" vs-name="disbursementType" @change="handleDisbursementMethod">Select an account</vs-radio><br>
            <div v-if="disbursement.method == 'account'">
              <vs-select class="mt-4 w-3/5" v-model="selectedAccount" @change="handleAccountChange">
                <div :key="index" v-for="(bank, index) in banks">
                  <vs-select-group :title="bank.title" v-if="bank.accounts">
                    <vs-select-item :key="index" :value="item.value" :text="`${item.displayName} (${item.bsb} , ${item.accountNumber})`" v-for="(item, index) in bank.accounts" class="product-group" />
                  </vs-select-group>
                </div>
              </vs-select>
              <span class="text-danger" v-if="errors.has('disbursementAccount')">{{ errors.first("disbursementAccount") }}</span>
            </div>
          </vs-col>
          <vs-col vs-xs="12" vs-lg="6" v-if="enableManualWidget || disabledManualWidget">
            <vs-radio v-if="displayOptions" v-model="disbursement.method" vs-value="manual" vs-name="disbursementType" @change="handleDisbursementMethod">Enter details manually</vs-radio><br>
            <div class="flex flex-col gap-y-8 mb-8 mt-4 ds-wrapper" v-if="disbursement.method == 'manual'">
              <div class="w-full">
                <vs-input
                  name="accountName"
                  label="Account name"
                  v-model="disburse.accountName"
                  :success="accountNameValid"
                  :class="errors.has('accountName') ? 'hasError' : ''"
                  icon="done"
                  icon-after="true"
                  icon-size="small"
                  v-validate="'required'"
                  maxlength="32"
                  data-vv-as="account name"
                  @change="checkFieldValidation('accountName')"
                  :disabled="disabledManualWidget" />
                <span class="span-text-validation-danger" v-if="errors.has('accountName')"><span class="span-text-validation">{{ errors.first("accountName") }}</span></span>
              </div>
              <div class="flex flex-row gap-x-6">
                <div class="w-2/5">
                  <vs-input
                    data-vv-as="BSB"
                    label="BSB"
                    name="disbursementBsb"
                    :class="!bsbError ? 'hasError' : ''"
                    placeholder="000 - 000"
                    v-mask="'###-###'"
                    v-model="disburse.bsb"
                    :success="bsbSuccess"
                    icon="done"
                    icon-after="true"
                    icon-size="small"
                    v-validate="'required'"
                    @blur="handleBsb"
                    :disabled="disabledManualWidget"
                  />
                  <span class="span-text-validation-danger" v-if="errors.has('disbursementBsb')"><span class="span-text-validation">{{ errors.first("disbursementBsb") }}</span></span>
                  <span class="span-text-validation-danger" v-if="!showBsbValid"><span class="span-text-validation">Invalid BSB</span></span>
                </div>
                <div class="w-3/5">
                  <vs-input
                    name="accountNumber"
                    label="Account number"
                    v-model="disburse.accountNumber"
                    :success="accountNumberValid"
                    :class="errors.has('accountNumber') ? 'hasError' : ''"
                    icon="done"
                    icon-after="true"
                    icon-size="small"
                    v-validate="'required|alphanumFormat'"
                    data-vv-as="account number"
                    @change="checkFieldValidation('accountNumber')"
                    :disabled="disabledManualWidget" />
                  <span class="span-text-validation-danger" v-if="errors.has('accountNumber')"><span class="span-text-validation">{{ errors.first("accountNumber") }}</span></span>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row v-if="showDisbursementTerms">
          <div class="flex my-5">
            <div>
              <vs-checkbox class="mt-1" name="disbursementTerms" id="disbursementTerms" v-model="acceptDisbursementTerms"></vs-checkbox>
            </div>
            <div>
              <p class="text-sm">I acknowledge it is my responsibility to provide correct and valid bank account details for disbursement. {{ appName }} are not responsible for payments made to accounts I have incorrectly provided, nor will such errors release any party from their obligations under an agreement with {{ appName }}.</p>
              <p class="mt-2 text-sm text-danger" v-if="errors.has('disbursementTerms')">{{ errors.first("disbursementTerms") }}</p>
            </div>
          </div>
        </vs-row>
        <vs-row>
          <div class="w-full mt-4 flex">
            <vs-switch v-model="overrideReference" />
            <p class="ml-2">
              {{ refModel == "Bank" ? "Override the default reference we'll use when disbursing funds to your account" : "Override the default reference we'll use when disbursing funds to the nominated account" }}
            </p>
          </div>

          <div class="mt-5 ds-wrapper w-1/2">
            <label class="vs-input--label">
              Disbursement transaction reference
              <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '50px, 2px' }}}">
                <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
                <div class="popper py-2 px-4 text-center custom-popper">
                  <p class="text-primary">Letters, numbers and spaces only, <= 18 characters, it may still be edited/truncated by the receiving bank.</p>
                </div>
              </popper>
            </label>
            <template v-if="overrideReference">
              <vs-input
                data-vv-as="reference"
                name="disbursementReference"
                v-model="disbursementReference"
                maxlength="280"
                v-validate="'required|alphaNumSpace'"
                data-vv-validate-on="blur"
                @keypress="handleReferenceKeyPress($event)"
                @blur="handleChangeReference()"
              />
              <span class="text-danger" v-if="errors.has('disbursementReference')">{{ errors.first("disbursementReference") }}</span>
            </template>
            <template v-else>
              <vs-input name="disbursementReference" v-model="merchantDisbursementReference" :disabled="true" />
            </template>
          </div>
        </vs-row>
        <vs-row v-if="selectedPlan.allowDisbursementDateOverride">
          <div class="w-full mt-4 flex">
            <vs-switch v-model="allowDisbursementDateOverride" @input="handleDisbursementDate" />
            <p class="ml-2">Schedule disbursement for future date</p>
          </div>
          <div v-if="allowDisbursementDateOverride" class="mt-5 ds-wrapper">
            <div class="input-datepicker">
              <date-picker
                name="disbursementDate"
                id="disbursementDate"
                v-model="disbursementDate"
                lang="en"
                type="date"
                placeholder="DD/MM/YYYY"
                input-class="datepicker-input w-full"
                format="YYYY/MM/DD"
                :disabled-days="disabledDate"
                @change="handleDisbursementDate"
              ></date-picker>
            </div>
          </div>
        </vs-row>
      </vs-collapse-item>
    </vs-collapse>
  </vs-card>
</template>
<script>
import { mask } from "vue-the-mask";
import DatePicker from "vue2-datepicker";
import Popper from "vue-popperjs";
import moment from "moment-timezone";
import ToolTipIcon from "../../../../components/design-sys-components/icons/ToolTipIcon";

export default {
  components: { DatePicker, Popper, ToolTipIcon },
  props: ["banks", "disbursement", "isSubmitted", "linkedAccount", "merchantDisbursementReference", "refModel", "selectedAccountDetails", "selectedPlan", "showDisbursementTerms"],
  data() {
    return {
      appName: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "FlipPay",
      acceptDisbursementTerms: false,
      accountNameValid: false,
      accountNumberValid: false,
      bsbError: true,
      bsbSuccess: false,
      disburse: {
        accountName: null,
        bsb: null,
        accountNumber: null,
      },
      disbursementReference : null,
      initialLoad: true,
      manualWidgetDisabled: false,
      selectedAccount: null,
      showBsbValid: true,
      overrideReference: false,
      allowDisbursementDateOverride: false,
      disbursementDate: ""
    };
  },
  directives: {
    mask
  },
  watch: {
    async isSubmitted(val) {
      if (val) {
        if (this.disbursement.method == "account" && !this.selectedAccount) {
          this.errors.add({
            field: "disbursementAccount",
            msg: "Please select disbursement account"
          });
        }

        if (this.showDisbursementTerms && !this.acceptDisbursementTerms) {
          this.errors.add({
            field: "disbursementTerms",
            msg: "Please accept disbursement terms"
          });
        }

        this.startValidating();
      }
    },

    acceptDisbursementTerms() {
      if (this.acceptDisbursementTerms && this.errors.has("disbursementTerms")) {
        this.errors.remove("disbursementTerms");
      }

      this.$emit("updateDisbursementTerms", this.acceptDisbursementTerms);
    },

    overrideReference(val) {
      if (!val) {
        this.disbursementReference = null;
      }
    }
  },
  methods: {
    async startValidating() {
      await this.$validator.validate();
    },

    handleReferenceKeyPress(event) {
      const charCode = event.which || event.keyCode;

      // Check if the pressed key corresponds to a special character
      if (charCode >= 33 && charCode <= 47 ||
          charCode >= 58 && charCode <= 64 ||
          charCode >= 91 && charCode <= 96 ||
          charCode >= 123 && charCode <= 126) {
          event.preventDefault();
      }
    },

    handleChangeReference() {
      this.$emit("handleChangeDisbursementReference", this.disbursementReference);
    },

    handleDisbursementMethod() {
      this.acceptDisbursementTerms = false;
      this.$forceUpdate();
      this.clearValidations();
    },

    handleAccountChange() {
      this.$emit("changeLinkedAccount", this.selectedAccount);

      if (this.errors.has("disbursementAccount")) {
        this.errors.remove("disbursementAccount");
      }
    },

    clearValidations() {
      if (this.disbursement.method == "account") {
        this.accountNameValid = false;
        this.accountNumberValid = false;
        this.showBsbValid = true;
        this.bsbError = true;
        this.bsbSuccess = false;
        this.disburse = {
          accountName: null,
          bsb: null,
          accountNumber: null
        };

        if (this.errors.has("accountName")) {
          this.errors.remove("accountName");
        }

        if (this.errors.has("accountNumber")) {
          this.errors.remove("accountNumber");
        }

        this.$emit("setDisbursement", { disbursement: this.disburse, disabled: true });

        if (this.banks && (this.banks.length == 1) && this.banks[0].accounts && (this.banks[0].accounts.length == 1)) {
          this.selectedAccount = this.banks[0].accounts[0].value;
        }

        if (this.selectedAccount) {
          this.$emit("changeLinkedAccount", this.selectedAccount);
        }
      } else {
        this.errors.remove("disbursementAccount");
        this.selectedAccount = null;
        this.$emit("changeLinkedAccount", this.selectedAccount);
      }
    },

    handleBsb() {
      const bsbData = this.disburse.bsb.split("-");

      if (bsbData.length == 2 && bsbData[1].length == 3) {
        this.showBsbValid = true;
      } else {
        this.showBsbValid = false;
      }

      this.bsbSuccess = this.showBsbValid;
      this.bsbError = this.showBsbValid;
      this.$emit("setDisbursement", { disbursement: this.disburse, disabled: this.manualWidgetDisabled });
    },

    checkFieldValidation(field) {
      if (field == "accountName") {
        this.accountNameValid = this.disburse.accountName.length > 1;

        if (!this.accountNameValid && !this.errors.has("accountName")) {
          this.errors.add({
            field: "accountName",
            msg: "Please enter valid account name"
          });
        } else if (this.accountNameValid) {
          this.errors.remove("accountName");
        }
      } else if (field == "accountNumber") {
        this.accountNumberValid = this.disburse.accountNumber.length > 1 && !this.errors.has("accountNumber");

        if (!this.accountNumberValid && !this.errors.has("accountNumber")) {
          this.errors.add({
            field: "accountNumber",
            msg: "Please enter valid account number"
          });
        } else if (this.accountNumberValid) {
          this.errors.remove("accountNumber");
        }
      }

      this.$emit("setDisbursement", { disbursement: this.disburse, disabled: this.manualWidgetDisabled });
    },

    disabledDate(date) {
      const calendarDate = moment(date).format("YYYY-MM-DD");
      const today = moment.tz(this.timezone).startOf("day").format("YYYY-MM-DD");

      // Get the date 60 days from today in the specified timezone
      const dateHere = moment.tz(this.timezone).startOf("day").add(60, "days").format("YYYY-MM-DD");
      // Compare the provided date with today's date and the date 60 days from now
      return (moment(calendarDate).isBefore(today) || moment(calendarDate).isAfter(dateHere));
    },

    handleDisbursementDate() {
      if (!this.allowDisbursementDateOverride) {
        this.disbursementDate = "";
      }
      this.$emit("handleDisbursementDate", { allowDisbursementDateOverride: this.allowDisbursementDateOverride, disbursementDate: this.disbursementDate });
    }
  },
  mounted() {
    if (this.disbursement.method == "account" && this.banks && (this.banks.length == 1) && this.banks[0].accounts && (this.banks[0].accounts.length == 1)) {
      this.selectedAccount = this.banks[0].accounts[0].value;
    } else if (this.disbursement.method == "account" && this.linkedAccount && this.linkedAccount.bankId) {
      // pre-populate selected account
      this.banks.forEach((item) => {
        const selectedBank = item.accounts.findIndex((account) => account.bankId == this.linkedAccount.bankId);

        if (selectedBank >= 0) {
          this.selectedAccount = item.accounts[selectedBank].value;
        }
      });
    }

    if (this.selectedAccount) {
      this.$emit("changeLinkedAccount", this.selectedAccount); // set remittance email as per selected account/template default
    }

    document.body.classList.add("payment-request-form-select");
    this.initialLoad = false;
  },
  computed: {
    enableAccountWidget() {
      return this.disbursement && (this.disbursement.allowMerchantAccount || this.disbursement.allowExternalAccount);
    },

    enableManualWidget() {
      return (this.disbursement && this.disbursement.allowAnyBank);
    },

    displayOptions() {
      return this.enableAccountWidget && this.enableManualWidget;
    },

    disabledManualWidget() {
      const togglesDisabled = !this.disbursement.allowMerchantAccount && !this.disbursement.allowExternalAccount && !this.disbursement.allowAnyBank;
      const disabled = ["externalAccount", "merchantAccount"].includes(this.disbursement.type) && togglesDisabled && this.linkedAccount && this.linkedAccount.bankId ? true : false;
      this.manualWidgetDisabled = disabled;

      // pre-populate data in manual entry widget
      if ((disabled && this.selectedAccountDetails) || (this.initialLoad && this.disbursement.method == "manual" && this.disbursement.allowAnyBank && this.selectedAccountDetails)) {
        this.disburse = this.selectedAccountDetails;
        this.$emit("setDisbursement", { disbursement: this.disburse, disabled: disabled });
      }

      return disabled;
    },

    editableDisbursement() {
      let editable = true;

      if (this.disbursement.type == "customer") {
        editable = false;
      } else {
        editable = !this.disabledManualWidget;
      }

      return editable;
    }
  },
};
</script>

