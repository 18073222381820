<template>
  <div class="slideup mt-4">
    <vs-card class="your-pay-card mb-0 sm:mb-2 md:mb-4 lg:mb-6 bg-white recurr recurrSummary">
      <div slot="header">
        <h5 class="card-title text-white text-xl font-normal">
          {{ cardTitle }}
        </h5>
      </div>

      <!-- paylater payment summary -->
      <div v-if="selectedProduct.productType == 'B2C_BNPL'">
        <vs-row class="flex justify-evenly pay-later-card p-4">
          <div class="flex flex-col justify-center items-center">
            <img :src="update" alt="update" />
            <span class="dark-blue text-xs mt-2">Every</span>
            <span class="dark-blue text-xs">{{ everyNumber }} {{ weekOrMonthOrDay }}</span>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img :src="playCircle" alt="playcircle" />
            <span class="dark-blue text-xs mt-2">Starts</span>
            <span class="dark-blue text-xs">{{ formatScheduleDate(startDate) }}</span>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img :src="stopCircle" alt="stopcircle" />
            <span class="dark-blue text-xs mt-2">Ends after</span>
            <span class="dark-blue text-xs">{{ numberOfPayments }} payments</span>
          </div>
        </vs-row>
        <vs-row class="p-6">
          <div class="w-full flex flex-col">
            <vs-col vs-w="12">
              <vs-table :data="schedule" class="payment-schedule-table summary-table">
                <template slot="thead">
                  <vs-th class="text-base font-medium" style="color: #757575">{{ dateLabel }}</vs-th>
                  <vs-th class="text-base font-medium" style="color: #757575">{{ amountLabel }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.date" class="font-light text-base">{{ isToday(tr.date) ? "Today" : formatScheduleDate(tr.date) }}</vs-td>
                    <vs-td :data="tr.amount" class="sec-row-payment font-light text-base">$ {{ addZeroes(amount ? tr.amount : 0) }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-col>
            <hr class="mb-4 mt-4 line-hr" />
            <div class="flex justify-between">
              <span class="text-xl font-normal dark-blue">{{ totalAmountLabel }}</span>
              <span class="text-xl font-normal dark-blue">$ {{ addZeroes(totalCustomerPayable) }}</span>
            </div>
          </div>
          <vs-row class="flex justify-between mt-4">
            <span class="text-xs leading-tight font-light mb-2" v-if="selectedProduct.feeModel === 'MSF_ADDED'">
              {{ parseContent(selectedProduct.feeText ? selectedProduct.feeText : "") }}
            </span>
          </vs-row>
        </vs-row>
      </div>
      <div v-else class="p-0">
        <vs-row>
          <div class="flex flex-col m-auto gray w-full">
            <div class="grey-container py-4">
              <p class="dark-blue text-xs text-center">{{ message }}</p>
              <div class="flex items-center m-auto mt-3 justify-center">
                <img :src="calenderImage" alt="calenderImage" width="18" height="20"/>
                <span class="dark-blue text-xs ml-2 pt-1">{{ formatScheduleDate(maturityDate) }}</span>
              </div>
            </div>
          </div>
        </vs-row>
        <div class="p-6">
        <vs-row class="flex justify-between pb-2">
          <span class="text-base font-light leading-tight">{{ purchaseAmountLabel }}</span>
          <span class="text-base font-light leading-tight">${{ addZeroes(amount) }}</span>
        </vs-row>
        <vs-row class="flex justify-between pb-2">
          <span class="text-base font-light leading-tight">{{ feeLabel }}</span>
          <span class="text-base font-light leading-tight">
            ${{ addZeroes(serviceFee) }}
          </span>
        </vs-row>
        <hr class="mt-2 mb-4 line-hr" />
        <vs-row class="flex justify-between">
          <span class="text-xl font-normal dark-blue">{{ disbursementLabel }}</span>
          <span class="text-xl font-normal dark-blue">
            ${{ addZeroes(merchantAmount) }}
          </span>
        </vs-row>
        <vs-row class="flex justify-between">
          <span class="text-xl font-normal dark-blue">{{ totalAmountLabel }}</span>
          <span class="text-xl font-normal dark-blue">
            ${{ addZeroes(totalCustomerPayable) }}
          </span>
        </vs-row>
        <vs-row class="flex justify-between mt-4">
          <span class="text-xs leading-tight font-light mb-2" v-if="showConfigurableFeeText">
            {{ parseExtensionFeeContent(selectedProduct.configurableFeeText ? selectedProduct.configurableFeeText : "") }}
          </span>
        </vs-row>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import moment from "moment";

const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");

export default {
  props: [
    "amount",
    "paymentPageSetting",
    "selectedPlan",
    "transactionSchedule",
  ],

  data() {
    return {
      everyNumber: "1",
      weekOrMonthOrDay: "",
      startDate: "",
      numberOfPayments: 0,
      fee: "0",
      total: "",
      serviceFee: 0,
      update: update,
      playCircle: playCircle,
      msf: 0,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      scheduleBind: false,
      schedule: [],
      totalCustomerPayable: 0,
      selectedProduct: {},
      dateLabel: "",
      amountLabel: "",
      cardTitle: "",
      disbursementLabel: "",
      totalAmountLabel: "",
      purchaseAmountLabel: "",
      feeLabel: "",
      message: "",
      merchantAmount: 0
    };
  },

  methods: {
    moment(date) {
      return moment(date).format("D-MMM-YY");
    },

    isToday(date) {
      return moment(date).isSame(moment(), "day");
    },

    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    getPaylaterSchedule() {
      this.selectedProduct = this.selectedPlan;
      this.msf = this.selectedProduct.msf;

      const total = this.transactionSchedule.find((item) => item.type == "Total");
      const disbursement = this.transactionSchedule.find((item) => item.type == "Disbursement");
      const transactionFee = this.transactionSchedule.find((item) => item.type == "Fee");

      this.schedule = this.transactionSchedule.filter((item) => item.type == "Payment");

      if (this.schedule && this.schedule.length) {
        this.numberOfPayments = this.schedule.length;
        this.startDate = this.schedule[0].date;
      }

      this.totalCustomerPayable = this.amount ? total.amount : 0;
      this.merchantAmount = this.amount ? disbursement.amount : 0;
      this.serviceFee = this.amount ? transactionFee.amount : 0;
      this.scheduleBind = true;
      this.getSummary();
    },

    async getPlan() {
      const { amountLabel, cardTitle, dateLabel, disbursementLabel, feeLabel, message, purchaseAmountLabel, totalAmountLabel } = this.paymentPageSetting;

      this.amountLabel = amountLabel || "Amount";
      this.cardTitle = cardTitle || "Your payment";
      this.dateLabel = dateLabel || "Payement date";
      this.disbursementLabel = disbursementLabel || "Disbursement";
      this.feeLabel = feeLabel || "Fee";
      this.message = message || "Our share of proceeds will be deducted on ";
      this.purchaseAmountLabel = purchaseAmountLabel || "Purchase Amount";
      this.totalAmountLabel = totalAmountLabel || "Total";
    },

    getSummary() {
      switch (this.selectedProduct.paymentFrequency) {
        case "WEEKLY":
          this.weekOrMonthOrDay = "Week";
          break;

        case "FORTNIGHTLY":
          this.everyNumber = "1";
          this.weekOrMonthOrDay = "fortnight";
          break;

        case "DAILY":
          this.weekOrMonthOrDay = "Day";
          break;

        case "MONTHLY":
          this.weekOrMonthOrDay = "Month";
          break;
      }
    },

    parseContent(data) {
      if (!data) {
        return "";
      }

      data = data.split("{{Fee %}}").join(this.msf + "%");
      data = data.split("{{Fee $ amount}}").join("$"+this.calculateFees);

      return data;
    },

    parseExtensionFeeContent(data) {
      if (!data) {
        return "";
      }

      data = data.split("{{Fee %}}").join(this.selectedProduct.extension.fee + "%");
      data = data.split("{{Fee $ amount}}").join("$"+this.calculateExtensionFees);

      return data;
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },

  mounted() {
    this.getPlan();
    this.getSummary();
  },

  computed: {
    calculateFees() {
      const feeAmount = (this.msf * parseFloat(this.selectedProduct.purchaseAmount)) / 100;

      if (this.selectedProduct.minimumMsfFeeAmount && this.selectedProduct.minimumMsfFeeAmount > feeAmount) {
        return this.addZeroes(this.serviceFee);
      }

      return `${this.addZeroes(this.serviceFee)} (${this.msf}%)`;
    },

    calculateExtensionFees() {
      let extensionFee = "";

      if (this.selectedProduct.extension && this.selectedProduct.extension.isEnabledExtension) {
        const feeAmount = (this.selectedProduct.extension.fee * parseFloat(this.amount)) / 100;
        extensionFee = `${this.addZeroes(feeAmount)} (${this.selectedProduct.extension.fee}%)`;
      }

      return extensionFee;
    },

    maturityDate() {
      let date = this.selectedProduct && this.selectedProduct.maturityDate ? this.selectedProduct.maturityDate : null;

      if (this.schedule && this.schedule.length) {
        date = this.schedule[this.schedule.length - 1].date;
      }

      return date;
    },

    showConfigurableFeeText() {
      return String(this.selectedProduct.productType).includes("BNPO") && this.selectedProduct.extension && this.selectedProduct.extension.isEnabledExtension;
    }
  },
};
</script>

