<template>
  <div>
    <div class="mt-4">
      <label for="emailAddress" class="block w-full text-xm font-normal mt-8 pb-3 text-base">Email address</label>
      <template v-for="(email, key) in emails">
        <vs-input :value="email" :key="key" size="large" disabled  class="mt-2" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",

  props: {
    notification: { type:Object },
    userDetail: { type: Object },
    notificationEmail: { type: Array },
    defaultNotificationEmails: { type: Array }
  },

  data() {
    return {
      toMe: false,
    }
  },

  computed: {
    emails() {
      let data = [...this.defaultNotificationEmails];

      if (this.notification && this.notification.options.includes("REQUEST_SENDER")) {
        data.push(this.userDetail.email);
      }

      this.$emit("setNotificationEmail", data);

      data = data.filter(item => item);

      return data;
    }
  },
}
</script>

