<template>
  <div>
    <div class="flex mb-8">
      <vs-switch v-model="customerData.sendComms" /> <p class="ml-6">Email/SMS the payment request from {{ appName }}</p>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <div class="input-grp">
          <div class="mb-6">
            <label class="label w-full">Name <span v-if="customerData.sendComms" class="mid-blue">*</span></label>
            <vs-input
              class="w-full"
              size="large"
              v-model="customerData.customerName"
              name="customerName"
              id="customerName"
              v-validate="customerData.sendComms ? 'required' : ''"
              @input="checkForm"
            />
            <span class="text-danger text-sm" v-show="errors.has('customerName')">{{ errors.first("customerName") }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="flex flex-col">
          <div class="input-grp">
            <div class="mb-1">
              <label class="label w-full">Email <span v-if="customerData.sendComms" class="mid-blue">*</span></label>
              <vs-input
                class="w-full"
                size="large"
                v-model="customerData.customerEmail"
                name="customerEmail"
                id="customerEmail"
                v-validate="emailRules"
                @input="checkForm"
              />
            </div>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('customerEmail')">One of email or mobile is required (both recommended)</span>
          <div class="input-grp mt-4">
            <div class="mb-1">
              <label class="w-full">Mobile <span v-if="customerData.sendComms" class="mid-blue">*</span></label>
              <the-mask
                class="vs-inputx vs-input--input large"
                :mask="['#### ### ###']"
                v-model="customerData.customerMobile"
                masked
                name="customerMobile"
                ref="customerMobile"
                id="customerMobile"
                @input="checkForm"
                v-validate="{
                  required: customerData.sendComms && !customerData.customerEmail,
                  regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,
                }"
              />
            </div>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('customerMobile')">One of email or mobile is required (both recommended)</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";
const dictionary = {
  en: {
    attributes: {
      customerName: "customer name",
      customerEmail: "customer email",
      customerMobile: "customer mobile",
    },
  },
};
Validator.localize(dictionary);
export default {
  components: { TheMask },
  props: ["customerData", "isSubmitted"],
  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "FlipPay" : "Rello",
    };
  },
  updated() {
    this.auto_grow("custom-textarea");
  },
  watch: {
    async isSubmitted(value) {
      this.startValidating();
    },
  },
  computed: {
    emailRules() {
      return this.customerData.sendComms && this.customerData.customerMobile === "" ? "required|email" : "email";
    },
  },
  methods: {
    async startValidating() {
      await this.$validator.validate();
    },
    checkForm(){
      this.$emit("checkForm");
    },
    validateChildForm() {
      return this.$validator.validateAll();
    },
  },
};
</script>

